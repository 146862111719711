import React, { useState, useEffect, useContext } from "react";
import { PatientInfoCss } from "./PatientInfoCss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import IconButton from "@material-ui/core/IconButton";
import { withRouter } from 'react-router-dom';
import
{
  Button,
  Input,
  Chip,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  InputLabel,
  TextField,
  FormControlLabel,
  Switch,
  Backdrop,
  Checkbox,

} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useTheme } from "@material-ui/core/styles";
import PatientContext from "../../context/patient/patientContext";
import { useSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";

const GQL_CATEGORY_QUERY = gql`
  query {
    categories {
     
      name
    }
  }
`;

const GQL_UPDATE_PATIENT = gql`
  mutation($input: PatientInput) {
    updatePatient(patientInput: $input) {
      _id
      name
      yearOfBirth
      gender
      address
      phone
      medicalConditionText
      drugTakeText
      knowledgeText
      location
      insurance
      medicalConditionArray {
        name
        active
      }
      drugTakeArray {
        name
        active
      }
      knowledgeArray {
        name
        active
      }
      catagories
      hospital
      block
    }
  }
`;


const GQL_DELETE_PATIENT = gql`
mutation($ID:ID){
  removePatient(_id:$ID) {
    _id
    name
    yearOfBirth
    gender
    address
    phone
    medicalConditionText
    drugTakeText
    knowledgeText
    location
    insurance
  }
}
`;

const methods = [
  "Net / Facebook",
  "Patient referral",
  "Doctor referral",
  "Insurance Company",
  "Friend or Relation",
];
const conditions = [
  "Blood thinning drugs",
  "Hypertension",
  "Heart condition",
  "Diabetes",
];
const drugs = [
  "Blood thinning drugs",
  "Hypertension",
  "Heart condition",
  "Diabetes",
];


function getStyles(name, personName, theme)
{
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const PatientInfo = (props) =>
{
  const classes = PatientInfoCss();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const showMessage = (msg, variant) =>
  {
    let time = 1000;
    if (variant === "error") time = 3000;
    enqueueSnackbar(msg, { variant, autoHideDuration: time });
  };

  const initialState = {
    name: "",
    phone: "",
    yearOfBirth: "",
    address: "",
    gender: "",
    location: "",
    insurance: "",
    knowledgeArray: [],
    medicalConditionArray: [],
    drugTakeArray: [],
    medicalConditionText: "",
    drugTakeText: "",
    knowledgeText: "",
    catagories: [],
    hospital: "",
    block: false

  };
  const [patient, setPatient] = useState(initialState);
  const createFinalData = () =>
  {
    const newMedicalConditionArray = conditions.map((con) =>
    {
      return {
        name: con,
        active: patient.medicalConditionArray.includes(con) ? true : false,
      };
    });
    const newKnowledgeArray = methods.map((method) =>
    {
      return {
        name: method,
        active: patient.knowledgeArray.includes(method) ? true : false,
      };
    });

    const newDrugTakeArray = drugs.map((drug) =>
    {
      return {
        name: drug,
        active: patient.drugTakeArray.includes(drug) ? true : false,
      };
    });

    const finalData = {
      ...patient,
      knowledgeArray: newKnowledgeArray,
      medicalConditionArray: newMedicalConditionArray,
      drugTakeArray: newDrugTakeArray,
      yearOfBirth: Number(patient.yearOfBirth),
    };
    return finalData;
  };
  const patientContext = useContext(PatientContext);
  const createPatientStateData = () =>
  {
    const {
      _id,
      name,
      phone,
      address,
      yearOfBirth,
      gender,
      location,
      insurance,
      knowledgeArray,
      medicalConditionArray,
      drugTakeArray,
      medicalConditionText,
      drugTakeText,
      knowledgeText,
      catagories,
      hospital,
      block
    } = patientContext;

    setPatient({
      ...patient,
      _id,
      name,
      phone,
      address,
      yearOfBirth,
      gender,
      location,
      insurance,
      knowledgeArray: knowledgeArray
        .filter((v) => v.active && v.name)
        .map((v) => v.name),
      medicalConditionArray: medicalConditionArray
        .filter((v) => v.active && v.name)
        .map((v) => v.name),
      drugTakeArray: drugTakeArray
        .filter((v) => v.active && v.name)
        .map((v) => v.name),
      medicalConditionText,
      catagories,
      drugTakeText,
      knowledgeText,
      hospital,
      block
    });
  };
  useEffect(() =>
  {
    createPatientStateData();
  }, [patientContext]);

  const [enabled, setEnabled] = useState(true);

  const changeValue = (type) => (event) =>
  {
    setPatient({ ...patient, [type]: event.target.value });
  };

  const [updatePatient, { loading }] = useMutation(GQL_UPDATE_PATIENT);
  const [deletePatient] = useMutation(GQL_DELETE_PATIENT);
  const save = async () =>
  {
    try
    {
      const input = createFinalData();

      await updatePatient({
        variables: {
          input,
        },
      });

      patientContext.setCurrentPatient(input);

      showMessage("Updated", "success");
    } catch (error)
    {
      showMessage(error.message, "error");
    }
  };
  const removePatient = async () =>
  {
    try
    {
      await deletePatient({
        variables: {
          ID: patientContext._id
        }
      })
      showMessage("Deleted", "success");
      props.history.push('/home')
    } catch (error)
    {
      showMessage(error.message, "error");
    }
  }

  const [categoriesList, setCategoriesList] = useState([]);
  useQuery(GQL_CATEGORY_QUERY, {
    onCompleted: (data) =>
    {
      const newData = data.categories.map(e => e.name)
      setCategoriesList(newData);
    },
  });

  if (loading)
  {
    return (
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };



  return (
    <div className={classes.ContFlex}>
      <div className={classes.Column}>
        <TextField
          className={classes.TextField}
          label="Name"
          disabled={!enabled}
          value={patient.name}
          onChange={changeValue("name")}
        />
        <TextField
          className={classes.TextField}
          type="number"
          label="Phone"
          disabled={!enabled}
          value={patient.phone}
          onChange={changeValue("phone")}
        />
        <TextField
          className={classes.TextField}
          label="Address"
          disabled={!enabled}
          value={patient.address}
          onChange={changeValue("address")}
        />
        <TextField
          className={classes.TextField}
          type="number"
          label="Age"
          onChange={changeValue("yearOfBirth")}
          value={patient.yearOfBirth}
          disabled={!enabled}
        />

        <div className={classes.selectRow}>
          <FormControl variant="outlined">
            <InputLabel>Gender</InputLabel>
            <Select
              native
              disabled={!enabled}
              defaultValue="Male"
              value={patient.gender}
              onChange={changeValue("gender")}
              labelWidth="100"
              inputProps={{
                name: "gender",
                id: "gender",
              }}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel>Clinic</InputLabel>
            <Select
              native
              disabled={!enabled}

              value={patient.location}
              onChange={changeValue("location")}
              labelWidth="100"
              inputProps={{
                name: "clinic",
                id: "clinic",
              }}
            >
              <option value="Maadi">Maadi</option>
              <option value="El sayeda">El sayeda</option>
              <option value="EL Tagmo3">EL Tagmo3</option>
              <option value="Sheikh zayed">Sheikh zayed</option>
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel>Insurance</InputLabel>
            <Select
              native
              disabled={!enabled}
              defaultValue="none"
              value={patient.insurance}
              onChange={changeValue("insurance")}
              labelWidth="100"
              inputProps={{
                name: "insurance",
                id: "insurance",
              }}
            >
              <option value="none">none</option>
              <option>AXA</option>
              <option>CBE</option>
              <option>Cement</option>
            </Select>
            <FormControlLabel
              label="Block"
              control={
                <Checkbox
                  checked={patient.block}
                  onChange={() => { setPatient({ ...patient, block: !patient.block }) }}

                />}

            />
          </FormControl>
        </div>
      </div>

      <div className={classes.Column}>
        <FormControl variant="outlined">
          <InputLabel>Hospital</InputLabel>
          <Select
            native
            disabled={!enabled}
            defaultVal
            // value={patient.location}
            // onChange={changeValue("location")}
            labelWidth="100"
            inputProps={{
              name: "hospital",
              id: "hospital",
            }}
          >
            <option value="None">None</option>
            <option value="El Salam el Dawly">El Salam el Dawly</option>
            <option value="Kiliopatra">Kiliopatra</option>
            <option value="Andalosya">Andalosya</option>
          </Select>
        </FormControl>

        <Autocomplete
          className={classes.formControlMulti}
          multiple
          id="tags-standard"
          value={patient.knowledgeArray}
          onChange={(value, option) =>
          {
            setPatient({ ...patient, knowledgeArray: option });
          }}
          options={methods}
          getOptionLabel={(option) => option}
          // defaultValue={[top100Films[13]]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Methods of knowledge about the clinic"

            />
          )}
        />
        <Autocomplete
          className={classes.formControlMulti}
          multiple
          id="tags-standard"
          value={patient.medicalConditionArray}
          onChange={(value, option) =>
          {
            setPatient({ ...patient, medicalConditionArray: option });
          }}
          options={conditions}
          getOptionLabel={(option) => option}
          // defaultValue={[top100Films[13]]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Present of past medical conditions"

            />
          )}
        />
        <Autocomplete
          className={classes.formControlMulti}
          multiple
          id="tags-standard"
          value={patient.drugTakeArray}
          onChange={(value, option) =>
          {
            setPatient({ ...patient, drugTakeArray: option });
          }}
          options={drugs}
          getOptionLabel={(option) => option}
          // defaultValue={[top100Films[13]]}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Medication currently using"

            />
          )}
        />







        <Autocomplete
          className={classes.formControlMulti}
          multiple
          id="categories"
          value={patient.catagories}
          onChange={(value, option) =>
          {
            setPatient({ ...patient, catagories: option });
          }}
          options={categoriesList}
          getOptionLabel={(option) => option}
          disabled={!enabled}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Categories" />
          )}
        />


      </div>

      <div className={classes.Column}>
        <TextField
          onChange={changeValue("knowledgeText")}
          value={patient.knowledgeText}
          disabled={!enabled}
          className={classes.TextFieldMulti}
          label="Methods of knowledge "
          multiline
          rows="4"
          variant="outlined"
        />
        <TextField
          value={patient.medicalConditionText}
          onChange={changeValue("medicalConditionText")}
          disabled={!enabled}
          className={classes.TextFieldMulti}
          label="Medical conditions"
          multiline
          rows="4"
          defaultValue=""
          variant="outlined"
        />
        <TextField
          onChange={changeValue("drugTakeText")}
          value={patient.drugTakeText}
          className={classes.TextFieldMulti}
          label="Medication currently using"
          multiline
          rows="4"
          disabled={!enabled}
          defaultValue=""
          variant="outlined"
        />

        <div className={classes.Buttons}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!enabled}
            onClick={save}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <FormControlLabel
            control={
              <Switch
                className={classes.button}
                checked={enabled}
                onChange={() =>
                {
                  setEnabled(!enabled);
                }}
                name="enabled"
              />
            }
            label="Edit"
          />
          <IconButton
            aria-label="delete"
            onClick={removePatient}
            color="secondary"
            disabled={!enabled}
            className={classes.button}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PatientInfo);
