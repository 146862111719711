

export default (state, action) =>
{


    switch (action.type)
    {


        case 'SET_DATE':
            return {
                ...state,
                date: action.payload
            }
        case 'SET_WARN':
            return {
                ...state,
                warn: action.payload
            }




    } 

};