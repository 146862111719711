import React, { useState, forwardRef, useContext, useEffect } from "react";
import gql from "graphql-tag";
import UserContext from "../../context/user/userContext";
import { InsuranceCss } from "./InsuranceCss";
import clsx from "clsx";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import MaterialTable, { MTableToolbar } from "material-table";
import LaunchIcon from "@material-ui/icons/Launch";
import PatientContext from '../../context/patient/patientContext'
import { ExportToCsv } from 'export-to-csv';
import
{
  Select,
  FormControl,
  InputLabel,
  Container,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";


import
{
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import
{
  Search,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Check,
  FilterList,
  Remove,
  Edit,
  AddBox,
  Clear,
  DeleteOutline,
  ArrowDownward,
} from "@material-ui/icons";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const GQL_GET_INSURANCE = gql`
query($From:String,$To:String,$Company:String){
 
  insurance(from:$From,to:$To,company:$Company) {
    _id
    date
    insurance
    price
    owner {
      _id
      name
    }
  
  }
}
`
const GQL_GET_PATIENT = gql`

query ($ID:ID) {
 patient(_id:$ID) {
   _id
   name
   yearOfBirth
   gender
   address
   phone
   catagories
   medicalConditionText
   drugTakeText
   knowledgeText
   medicalConditionArray {
    name
    active
  }
  drugTakeArray {
    name
    active
  }
  knowledgeArray {
    name
    active
  }
   location
   insurance
   hospital
   block
 }
}

`


const Insurance = (props) =>
{
  const patientContext = useContext(PatientContext)
  const userContext = useContext(UserContext);
  useEffect(() =>
  {
    if (!userContext.token)
    {
      props.history.push("/");
    }
  }, [userContext.token, props.history]);
  const classes = InsuranceCss();
  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "owner.name" },

      { title: "Company", field: "insurance" },
      { title: "Date", field: "date", type: "date" },
    ],
    data: [

    ],
  });


  const [fromDate, setFromDate] = useState(
    new Date().toLocaleDateString()
  );
  const [toDate, setToDate] = useState(
    new Date().toLocaleDateString()
  );
  const [company,setCompany] =useState('All')


  const handleFromChange = (date) =>
  {
    setFromDate(new Date(date).toLocaleDateString());
  };

  const handleToChange = (date) =>
  {
    setToDate(new Date(date).toLocaleDateString());
  };

  const companyChange = (event)=>{

    setCompany(event.target.value)
  }

  useQuery(GQL_GET_INSURANCE, {
    variables: {

      From:fromDate,
      To:toDate,
      Company:company

     },
     onCompleted: data =>{

      setState({...state,data:data.insurance})
     }


  })

  const [getPatient, { loading }] = useLazyQuery(GQL_GET_PATIENT,
    {
      onCompleted: async data =>
      {

        patientContext.setCurrentPatient(data.patient)


        props.history.push('/profile')




      }
    }


  );
  return (
    <Container maxWidth="lg" className={classes.content}>
      <div className={classes.toolbar} />
      <MaterialTable
        icons={tableIcons}
        title="Insurance"
        columns={state.columns}
        data={state.data}
        components={{
          Toolbar: (props) => (
            <div className={classes.menu}>
              <MTableToolbar {...props} />

              <FormControl
                className={clsx(classes.formControlSelect, classes.selectStyle)}
              >
                <InputLabel htmlFor="gender"></InputLabel>
                <Select
                  native
                  defaultValue="All"
                  value={company}
                  onChange={companyChange}
                  labelWidth="100"
                  inputProps={{
                    name: "menu",
                    id: "menu",
                  }}
                >
                  <option value="All">All</option>
                  <option value="AXA">AXA</option>
                  <option value="CBE">CBE</option>
                  <option value="Cement">Cement</option>
                </Select>
              </FormControl>
              <div className={classes.date}>
                <div className={classes.date2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} p={1}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="From"
                      format="MM/dd/yyyy"
                      value={fromDate}
                      onChange={handleFromChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className={classes.date2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} p={1}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="To"
                      format="MM/dd/yyyy"
                      value={toDate}
                      onChange={handleToChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                </div>
              </div>
            </div>
          ),
        }}
        options={{
          search: true,
          exportButton: true,
          pageSize: 20,
          exportCsv: (columns, data) =>
          {
            const options = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              filename: 'Insurance',
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: true,
            
            };

            const csvExporter = new ExportToCsv(options);
         
            const newData = data.map(e => {
              return {
               name:e.owner.name,
               company:e.insurance,
               date:e.date,
              
              }
            })
         
            csvExporter.generateCsv(newData);
          }
        }}
        actions={[
                  (rowData) => ({
                    icon: () => <LaunchIcon />,
                    tooltip: "Open",
                    onClick: async () =>
                    {

                      try
                      {

                        await getPatient({
                          variables: {
                            ID: rowData.owner._id
                          }
                        })

                      } catch (error)
                      {

                        console.log({ error })
                      }

                    }
                  }),
                ]}
      />
    </Container>
  );
};

export default Insurance;
