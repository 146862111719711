import React, { useState, forwardRef, useContext } from "react";
import PatientContext from "../../context/patient/patientContext";
import MaterialTable from "material-table";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { TextField, useTheme } from "@material-ui/core";
import {
    Search,
    ViewColumn,
    SaveAlt,
    ChevronLeft,
    ChevronRight,
    FirstPage,
    LastPage,
    Check,
    FilterList,
    Remove,
    Edit,
    AddBox,
    Clear,
    DeleteOutline,
    ArrowDownward,
} from "@material-ui/icons";

const GQL_APPOINTMENTS = gql`
  query($id: ID) {
    patientAppointments(ownerId: $id) {
      _id
      note
      date
      kind
      insurance
      price
    }
  }
`;
const GQL_ADD_APPOINTMENT = gql`
  mutation($appointmentInput: AppointmentInput) {
    addAppointment(appointmentInput: $appointmentInput) {
      _id
      note
      date
      kind
      insurance
      price
    }
  }
`;
const GQL_UPDATE_APPOINTMENT = gql`
  mutation($appointmentInput: AppointmentInput) {
    updateAppointment(appointmentInput: $appointmentInput) {
      _id
      note
      date
      kind
      insurance
      price
    }
  }
`;
const GQL_DELETE_APPOINTMENT = gql`
  mutation($id: ID) {
    removeAppointment(_id: $id) {
      _id
      note
      date
      kind
      insurance
      price
    }
  }
`;


const insuranceObj = { none: "none", AXA: "AXA", CBE: "CBE", Cement: "Cement" };
const Finance = (props) => {
    const patientContext = useContext(PatientContext);

  const [state, setState] = useState({
    columns: [
      
      {
        title: "Date",
        field: "date",
        type: "date",
        emptyValue: Date.now(),
        editable: 'never'
      },
      {
        title: "Type",
        field: "kind",
        lookup:{"كشف":"كشف","متابعة عمليات":"متابعة عمليات","استشارة":"استشارة","تعاقد":"تعاقد","Operation":"Operation","Clinic Procedure":"Clinic Procedure"},

        editable: 'never',
        emptyValue: "كشف",
      },
      {
        title: "Insurance",
        field: "insurance",
        lookup: { none: "none", AXA: "AXA", CBE: "CBE", Cement: "Cement" },
        editable: 'never',
        emptyValue: insuranceObj.none,
      },
      {
        title: "Price",
        field: "price",
        type: "numeric",
        emptyValue: "0"
      }
    ],
    data: [],
  });

  const { enqueueSnackbar } = useSnackbar();
  const showMessage = (msg, variant) => {
    let time = 1000;
    if (variant === "error") time = 3000;
    enqueueSnackbar(msg, { variant, autoHideDuration: time });
  };

  const [addAppointment] = useMutation(GQL_ADD_APPOINTMENT);
  const [updateAppointment] = useMutation(GQL_UPDATE_APPOINTMENT);
  const [removeAppointment] = useMutation(GQL_DELETE_APPOINTMENT);
  const { loading, refetch } = useQuery(GQL_APPOINTMENTS, {
    variables: {
      id: patientContext._id,
    },
    onCompleted: (data) => {
      setState({ ...state, data: data.patientAppointments });
    },
  });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <MaterialTable
      style={{ marginTop: useTheme().spacing(2) }}
      icons={tableIcons}
      isLoading={loading}
      title={patientContext.name}
      columns={state.columns}
      data={state.data}
      options={{
        search: true,
        exportButton: true,
        pageSize: 10,
        sorting: true,
      }}
      editable={{
       
        onRowUpdate: async (newData, oldData) => {
           
          try {
            const newDataGQL = await updateAppointment({
              variables: {
                appointmentInput: {
                  _id: newData._id,
                  note: newData.note,
                  kind: newData.kind,
                  insurance: newData.insurance,
                  price: Number(newData.price),
                  date: new Date(newData.date).toLocaleDateString("en-US"),
                },
              },
            });

            console.log(newDataGQL.data.updateAppointment);
            await refetch();
            if (oldData) {
              setState((prevState) => {
                const data = [...prevState.data];
                data[data.indexOf(oldData)] = newDataGQL.data.updateAppointment;
                return { ...prevState, data };
              });
            }

            showMessage("Saved", "success");
          } catch (error) {
            showMessage(error.message, "error");
          }
        },
      
      }}
    />
  );
};

export default Finance;