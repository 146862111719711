import React, { forwardRef, useEffect, useContext, useState } from 'react'
import { withRouter } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from "material-table";
import { AllPatientsCss } from './AllPatientsCss'
import UserContext from '../../context/user/userContext'
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import DateFnsUtils from "@date-io/date-fns";
import { ExportToCsv } from 'export-to-csv';

import
{
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import
{
    Search,
    ViewColumn,
    SaveAlt,
    ChevronLeft,
    ChevronRight,
    FirstPage,
    LastPage,
    Check,
    FilterList,
    Remove,
    Edit,
    AddBox,
    Clear,
    DeleteOutline,
    ArrowDownward,
} from "@material-ui/icons";
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const GQL_NON_BLOCKED = gql`
query ($From:String,$To:String) {
    nonBlockedPatients(from:$From,to:$To) {
      _id
      name
      yearOfBirth
      gender
      address
      phone
      location
      insurance
     
    } 
    
  }

`
const AllPatients = (props) =>
{
    const userContext = useContext(UserContext);
    const classes = AllPatientsCss()
    useEffect(() =>
    {

        if (!userContext.token)
        {
            props.history.push("/");
        }
    }, [userContext.token, props.history]);

    const [state, setState] = useState({
        columns: [
            { title: "Name", field: "name" },

            { title: "Phone", field: "phone" , type:String},
            { title: "Address", field: "address" },
            { title: "Age", field: "yearOfBirth" },
            { title: "Gender", field: "gender" },
            { title: "Clinic", field: "location" },
            { title: "Insurance", field: "insurance" },
        ],
        data: [

        ],
    });
    const [fromDate, setFromDate] = useState(
        new Date().toLocaleDateString()
    );
    const [toDate, setToDate] = useState(
        new Date().toLocaleDateString()
    );
    const handleFromChange = (date) =>
    {
        setFromDate(new Date(date).toLocaleDateString());
    };

    const handleToChange = (date) =>
    {
        setToDate(new Date(date).toLocaleDateString());
    };
    const { loading } = useQuery(GQL_NON_BLOCKED, {
        variables: {
            From: fromDate,
            To: toDate,
        },
        onCompleted: data =>
        {
            setState({ ...state, data: data.nonBlockedPatients })
        }
    })
    return (
        <main className={classes.content}>
            <div className={classes.toolbar}>
                <MaterialTable
                    data={state.data}
                    columns={state.columns}
                    icons={tableIcons}
                    isLoading={loading}
                    title="All Patients"
                    options={{
                        search: true,
                        exportButton: true,
                        pageSize: 20,
                        exportCsv: (columns, data) =>
                        {
                            const options = {
                                fieldSeparator: ',',
                                quoteStrings: '"',
                                decimalSeparator: '.',
                                showLabels: true,
                                filename: 'All Patients',
                                useTextFile: false,
                                useBom: true,
                                useKeysAsHeaders: true,

                            };

                            const csvExporter = new ExportToCsv(options);

                            const newData = data.map(e =>
                            {
                              
                                return {
                                    name: e.name,
                                    yearOfBirth: e.yearOfBirth,
                                    gender: e.gender,
                                    address: e.address,
                                    phone: String(e.phone),
                                    location: e.location,
                                    insurance: e.insurance


                                }
                            })

                            csvExporter.generateCsv(newData);
                        }
                    }}
                    components={{
                        Toolbar: (props) => (
                            <div >
                                <MTableToolbar {...props} />
                                <div className={classes.date}>
                                    <div className={classes.date2}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} p={1}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="From"
                                                format="MM/dd/yyyy"
                                                value={fromDate}
                                                onChange={handleFromChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                    <div className={classes.date2}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} p={1}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="To"
                                                format="MM/dd/yyyy"
                                                value={toDate}
                                                onChange={handleToChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </div>
                                </div>
                            </div>
                        ),
                    }}
                />
            </div>
        </main>
    )
}

export default withRouter(AllPatients)
