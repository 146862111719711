import React, { useContext, useEffect, forwardRef, useState } from "react";
import UserContext from "../../context/user/userContext";
import { Grid, Paper } from "@material-ui/core";
import
{
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  BarSeries,
  Legend,
  Tooltip,
  PieSeries,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker } from "@devexpress/dx-react-chart";
import { DashBoardStyle } from "./DashBoardCss";
import MaterialTable from 'material-table';
import
{
  Search,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Check,
  FilterList,
  Remove,
  Edit,
  AddBox,
  Clear,
  DeleteOutline,
  ArrowDownward,
  Refresh,
} from "@material-ui/icons";
import LaunchIcon from "@material-ui/icons/Launch";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TodayIcon from '@material-ui/icons/Today';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GQL_MONEY = gql`
query{
  getMoney
}

`

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};


const DashBoardMain = (props) =>
{
  const userContext = useContext(UserContext);
  useEffect(() =>
  {
    if (!userContext.token)
    {
      props.history.push("/");
    }

  }, [userContext.token, props.history]);
  const classes = DashBoardStyle();
  const [refresh, setRefresh] = React.useState(true);
  const [open, setOpen] = useState(true)
  const [state, setState] = React.useState({
    today: 0,
    week: 0,
    month: 0
  });
  useQuery(GQL_MONEY, {
    onCompleted: (data) =>
    {

      setState({ today: data.getMoney[0], week: data.getMoney[1], month: data.getMoney[2] })
      setOpen(false);
    },
    variables: {
      refresh: refresh
    }
  })


  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={6}>
          <Paper style={{ padding: "10px 0px" }} elevation={3}>
            <Chart
              height={273}
              data={[

                { year: "Net / Facebook", population: 1000 },
                { year: "Patient referral", population: 3000 },
                { year: "Doctor referral", population: 500 },
                { year: "Insurance Company", population: 250 },
                { year: "Friend or Relation", population: 250 },
              ]}

            >
              <EventTracker />
              <Tooltip />
              <PieSeries valueField="population" argumentField="year" />
              <Legend />
            </Chart>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <Paper style={{ padding: "10px 0px" }} elevation={3} >
            <div className={classes.center}>
              {open && <CircularProgress />}
            </div>
            <div className={classes.space}>
              <Typography variant="h5" gutterBottom className={classes.txt}>
                Revenue
          </Typography>
              <div>
                <IconButton color="primary" aria-label="refresh" onClick={() =>
                {
                  setOpen(true)
                  setRefresh(!refresh)
                }} >
                  <RefreshIcon />
                </IconButton>

              </div>
            </div>
            <List >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <TodayIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Today" secondary={state.today} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <ViewWeekIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Week" secondary={state.week} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <EventAvailableIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Month" secondary={state.month} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: "10px 0px" }} elevation={3}>
            <Chart
              height={300}
              data={[
                { year: "AXA", population: 71 },
                { year: "CBE", population: 87 },
                { year: "Cement", population: 20 },
              ]}
            >
              <ArgumentAxis />
              <ValueAxis />
              <Legend />
              <EventTracker />
              <Tooltip />
              <BarSeries
                name="Insurance"
                valueField="population"
                argumentField="year"
              />
            </Chart>
          </Paper>
        </Grid>
      </Grid>
    </main>
  );
};
export default DashBoardMain;

