import React, { useContext, Fragment, useState, useEffect } from "react";
import { navbarStyle } from "./navbarCss";
import Button from '@material-ui/core/Button';
import SearchIcon from "@material-ui/icons/Search";
import Search from "./Search";
import
  {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    CssBaseline,
    Hidden,
    FormControl,
    InputLabel,
    Select,
  } from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";

import userContext from "../../context/user/userContext";

import DrawerItems from "./DrawerItems";
import { event } from "react-ga";

const Navbar = (props) =>
{
  const user = useContext(userContext);
  const classes = navbarStyle();
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const handleDrawer = () =>
  {
    setMobileDrawer(!mobileDrawer);
  };
  const initialState = {
    name: "",
    phone: "",
    yearOfBirth: "",
    address: "",
    gender: "",
    location: "",
    insurance: "",
    knowledgeArray: [],
    medicalConditionArray: [],
    drugTakeArray: [],
    medicalConditionText: "",
    drugTakeText: "",
    knowledgeText: "",
    catagories: [],
  };
  const [enabled, setEnabled] = useState(true);
  const [type, setType] = useState('All');
  const [type2, setType2] = useState('All')
  const changeValue = (event) =>
  {

    setType(event.target.value)

  };

  const changeValue2 = (event) =>
  {

    setType2(event.target.value)
  }

  return (
    <Fragment>
      <div className={classes.nav}>
        <CssBaseline />
        <AppBar position="fixed" className={user.token && classes.appBar}>
          <Toolbar>
            {user.token && (
              <IconButton
                className={classes.drawerBtn}
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawer}
              >
                <MenuIcon />
              </IconButton>

            )}

            <Hidden Hidden mdUp implementation="css">
              <Typography variant="h8" noWrap>
                {" "}
              </Typography>
            </Hidden>

            <Hidden Hidden smDown implementation="css">
              <Typography variant="h6" noWrap>
                {" "}
                Clinic System{" "}
              </Typography>
            </Hidden>

            {user.token && (
              <div className={classes.root}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>

                  <Search classes={classes} filter={type} filter2={type2} />

                </div>

                <FormControl variant="outlined">
                  <InputLabel>Clinic</InputLabel>
                  <div className={classes.font}>

                    <Select
                      native
                      disabled={!enabled}
                      defaultValue="All"
                      value={type}
                      onChange={changeValue}
                      labelWidth="50"
                      inputProps={{
                        name: "clinic",
                        id: "clinic",
                      }}
                    >
                      <option value="All">All</option>
                      <option value="Maadi">Maadi</option>
                      <option value="El sayeda">El sayeda</option>
                      <option value="EL Tagmo3">EL Tagmo3</option>
                      <option value="Sheikh zayed">Sheikh zayed</option>
                    </Select>
                  </div>
                </FormControl>
                <FormControl variant="outlined">
                  <InputLabel>Hospital</InputLabel>
                  <div className={classes.font}>

                    <Select
                      native
                      disabled={!enabled}
                      defaultValue="All"
                      value={type2}
                      onChange={changeValue2}
                      labelWidth="50"
                      inputProps={{
                        name: "Hospital",
                        id: "Hospital",
                      }}
                    >
                      <option value="All">All</option>

                      <option value="ASSIH">ASSIH</option>
                      <option value="Cleopatra">Cleopatra</option>
                      <option value="Katamiya">Katamiya</option>

                    </Select>
                  </div>
                </FormControl>


                <div className={classes.root2} >
                  <Button onClick={() => { window.location.reload() }} color="inherit"  >Logout</Button>

                </div>
              </div>
            )}


          </Toolbar>
        </AppBar>
      </div>

      {user.token && (
        <div>
          <Hidden Hidden smUp implementation="css">
            <Drawer
              open={mobileDrawer}
              onClose={handleDrawer}
              variant="temporary"
              ModalProps={{ keepMounted: true }}
              classes={{ paper: classes.drawerPaper }}
            >
              <DrawerItems isMobile={true} handleDrawer={handleDrawer} />
            </Drawer>
          </Hidden>

          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{ paper: classes.drawerPaper }}
            >
              <DrawerItems isMobile={false} handleDrawer={handleDrawer} />
            </Drawer>
          </Hidden>
        </div>
      )}
    </Fragment>
  );
};
export default Navbar;
