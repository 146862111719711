import React, { useState, forwardRef, useContext, useEffect } from "react";
import gql from "graphql-tag";
import UserContext from "../../context/user/userContext";
import { FinanceMainCss } from "./FinanceMainCss";
import clsx from "clsx";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import LaunchIcon from "@material-ui/icons/Launch";
import PatientContext from '../../context/patient/patientContext'
import { ExportToCsv } from 'export-to-csv';
import MaterialTable, { MTableToolbar } from "material-table";

import
  {
    Select,
    FormControl,
    InputLabel,
    Container,
  } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";


import
  {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from "@material-ui/pickers";
import
  {
    Search,
    ViewColumn,
    SaveAlt,
    ChevronLeft,
    ChevronRight,
    FirstPage,
    LastPage,
    Check,
    FilterList,
    Remove,
    Edit,
    AddBox,
    Clear,
    DeleteOutline,
    ArrowDownward,
  } from "@material-ui/icons";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const GQL_GET_FINANCE = gql`
query($From:String,$To:String,$Kind:String){
 
  finance(from:$From,to:$To,kind:$Kind) {
    _id
    date
    kind
    insurance
    price
    owner {
      _id
      name
    }
  
  }
}
`
const GQL_GET_PATIENT = gql`

query ($ID:ID) {
 patient(_id:$ID) {
   _id
   name
   yearOfBirth
   gender
   address
   phone
   catagories
   medicalConditionText
   drugTakeText
   knowledgeText
   medicalConditionArray {
    name
    active
  }
  drugTakeArray {
    name
    active
  }
  knowledgeArray {
    name
    active
  }
   location
   insurance
   hospital
   block
 }
}

`
const FinanceMain = (props) =>
{
  const userContext = useContext(UserContext);
  const patientContext = useContext(PatientContext)
  const [fromDate, setFromDate] = useState(
    new Date().toLocaleDateString()
  );
  const [toDate, setToDate] = useState(
    new Date().toLocaleDateString()
  );
  const [kind, setKind] = useState('All')


  const handleFromChange = (date) =>
  {
    setFromDate(new Date(date).toLocaleDateString());
  };

  const handleToChange = (date) =>
  {
    setToDate(new Date(date).toLocaleDateString());
  };
  const [getPatient, { loading }] = useLazyQuery(GQL_GET_PATIENT,
    {
      onCompleted: async data =>
      {

        patientContext.setCurrentPatient(data.patient)


        props.history.push('/profile')




      }
    })


  useEffect(() =>
  {
    if (!userContext.token)
    {
      props.history.push("/");
    }
  }, [userContext.token, props.history]);
  const classes = FinanceMainCss();
  const [state, setState] = useState({
    columns: [
      { title: "Name", field: "owner.name" },

      { title: "Type", field: "kind" },
      { title: "Date", field: "date", type: "date" },
      { title: "Price", field: "price" },
    ],
    data: [

    ],
  });

  useQuery(GQL_GET_FINANCE, {
    variables: {

      From: fromDate,
      To: toDate,
      Kind: kind

    },
    onCompleted: data =>
    {

      setState({ ...state, data: data.finance })
    }


  })

  return (
    <Container maxWidth="lg" className={classes.content}>
      <div className={classes.toolbar} />
      <MaterialTable
        icons={tableIcons}
        title="Finance"
        columns={state.columns}
        data={state.data}
        components={{
          Toolbar: (props) => (
            <div className={classes.menu}>
              <MTableToolbar {...props} />

              <FormControl
                className={clsx(classes.formControlSelect, classes.selectStyle)}
              >
                <InputLabel htmlFor="gender"></InputLabel>
                <Select
                  native
                  defaultValue="All"
                  value={kind}
                  onChange={(e) =>
                  {
                    setKind(e.target.value);
                  }}
                  labelWidth="100"
                  inputProps={{
                    name: "menu",
                    id: "menu",
                  }}
                >
                  <option value="All">All</option>
                  <option value="كشف">كشف</option>
                  <option value="Operation">Operation</option>
                  <option value="Clinic Procedure">Clinic Procedure</option>
                </Select>
              </FormControl>
              <div className={classes.date}>
                <div className={classes.date2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} p={1}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="From"
                      format="MM/dd/yyyy"
                      value={fromDate}
                      onChange={handleFromChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className={classes.date2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} p={1}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="To"
                      format="MM/dd/yyyy"
                      value={toDate}
                      onChange={handleToChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                </div>
              </div>
            </div>
          ),
        }}
        options={{
          search: true,
          exportButton: true,
          pageSize: 20,
          exportCsv: (columns, data) =>
          {
            const options = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              filename: 'Finance',
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: true,
            
            };

            const csvExporter = new ExportToCsv(options);
         
            const newData = data.map(e => {
              return {
               name:e.owner.name,
               kind:e.kind,
               date:e.date,
               price:e.price

              }
            })
         
            csvExporter.generateCsv(newData);
          }
        }}
        actions={[
          (rowData) => ({
            icon: () => <LaunchIcon />,
            tooltip: "Open",
            onClick: async () =>
            {

              try
              {

                await getPatient({
                  variables: {
                    ID: rowData.owner._id
                  }
                })

              } catch (error)
              {

                console.log({ error })
              }

            }
          }),
        ]}
      />
    </Container>
  );
};

export default FinanceMain;
