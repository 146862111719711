import React, { useState, useContext, forwardRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DrawerContext from "../../context/drawer/drawerContext";
import MaterialTable from "material-table";
import { ConsultationsCss } from "./ConsultationsCss";
import ConsultationsContext from "../../context/consultation/consultationContext";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Check from "@material-ui/icons/Check";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import Edit from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import gql from "graphql-tag";
import ConsolationContext from '../../context/consultation/consultationContext'
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ExportToCsv } from 'export-to-csv';
import
{
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Container,
  useTheme,
} from "@material-ui/core";
import { Satellite } from "@material-ui/icons";

const Transition = forwardRef(function Transition(props, ref)
{
  return <Slide direction="up" ref={ref} {...props} />;
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const GQL_GET_CONS = gql`
query {
Consultations {
  _id
  day
  date
  left
}
}
`
const GQL_EDIT_CONS = gql`
mutation($ID:ID,$BOOL:Boolean){
  
  editCon(_id:$ID,operation:$BOOL)
}
`


const Consultations = (props) =>
{
  const consolationContext = useContext(ConsolationContext);
  const classes = ConsultationsCss();
  const drawerContext = useContext(DrawerContext);
  const consultationsContext = useContext(ConsultationsContext)
  const handleClose = () =>
  {
    drawerContext.handleConsultations();
  };
  const [reload, setReload] = useState(false);
  const { loading } = useQuery(GQL_GET_CONS, {
    onCompleted: (data) =>
    {

      setState({ ...state, data: data.Consultations })
    },
    variables: {
      refresh: reload
    },
    fetchPolicy: "cache-and-network"
    ,
    pollInterval: 10000
  })

  const [editCon] = useMutation(GQL_EDIT_CONS);
  const [state, setState] = useState({
    columns: [
      { title: "Date", field: "date" },
      { title: "Day", field: "day" },
      { title: "Left", field: "left", type: "numeric" },
    ],
    data: [

    ],

  });
  const getRowColor = (day) =>
  {
    switch (day)
    {
      case days[new Date().getDay()]:
        return "#3f51b5";

    }
  };

  return (
    <Dialog
      fullScreen
      open={drawerContext.consultations}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Consultations
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <MaterialTable
          isLoading={loading}
          style={{ marginTop: useTheme().spacing(2) }}
          icons={tableIcons}
          columns={state.columns}
          data={state.data}



          title="Consultations"
          options={{
            search: false,
            exportButton: true,
            rowStyle: (rowData) => ({
              backgroundColor: getRowColor(rowData.day),
            }),

            pageSize: 20,
            showTitle: false,
            exportCsv: (columns, data) =>
            {
              const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                filename: 'Consultations',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,

              };

              const csvExporter = new ExportToCsv(options);

              const newData = data.map(e =>
              {
                return {
                 
                  day:e.day,
                  date: e.date,
                  left: e.left

                }
              })

              csvExporter.generateCsv(newData);
            }
          }}

          actions={[
            (rowData) => ({
              icon: () => <AddIcon />,
              tooltip: "Add one",
              onClick: async (event, rowData) =>
              {
                consultationsContext.setDate(rowData.date)
                consolationContext.offWarn()
                await editCon({
                  variables: {
                    "ID": rowData._id,
                    "BOOL": false
                  }
                })
                setReload(!reload)
              },
              disabled: rowData.left == 5,
            }),
            (rowData) => ({
              icon: () => <RemoveIcon />,
              tooltip: "Remove one",
              onClick: async (event, rowData) =>
              {


                consultationsContext.setDate(rowData.date)
                consolationContext.offWarn()
                await editCon({
                  variables: {
                    "ID": rowData._id,
                    "BOOL": true
                  }
                })
                setReload(!reload)
              },
              disabled: rowData.left == 0,
            }),
          ]}
        />
      </Container>
    </Dialog>
  );
};

export default Consultations;
