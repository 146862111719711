import React, {useState} from "react";
import {
  Container,
  Avatar,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Fade,
  CircularProgress,
} from "@material-ui/core";
import { DashBoardStyle } from "./DashBoardCss";
import { Grid, Paper } from "@material-ui/core";



import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Typography from '@material-ui/core/Typography';
import TodayIcon from '@material-ui/icons/Today';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Backdrop from '@material-ui/core/Backdrop';

import RefreshIcon from '@material-ui/icons/Refresh';
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GQL_MONEY = gql`
query{
  getMoney
}

`

const DashBoardLogo = () => {
  const classes = DashBoardStyle();
  const [open, setOpen] = React.useState(true);
  const [refresh, setRefresh] = React.useState(true);

  const handleToggle = () => {
    setOpen(true);
    setRefresh(!refresh)
  };
  const [state, setState] = useState(0)


  useQuery(GQL_MONEY,{
    onCompleted: (data) =>{

      setState(data.getMoney[0])
      setOpen(false);
    },
    variables:{
      refresh:refresh
    }
  })
  return (
    <main className={classes.content}>
    <div className={classes.toolbar} />
    <div>
      <Grid item xs={12} sm={12} lg={6}>
        <Paper style={{ padding: "10px 0px" }} elevation={3} >
          <div className={classes.space}>
            <Typography variant="h5" gutterBottom className={classes.txt}>
              Revenue
   </Typography>
            <div>
              <IconButton color="primary" aria-label="refresh" onClick={handleToggle}>
                <RefreshIcon />
              </IconButton>
              <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </div>
          <List >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <TodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Today" secondary={state} />
            </ListItem>
          
          </List>
        </Paper>
      </Grid>
    </div>
    </main>
  );
};

export default DashBoardLogo;
