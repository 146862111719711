import { makeStyles } from "@material-ui/core/styles";


export const style = makeStyles((theme) => ({
    sub: {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        margin: "20px",
        wrap: "wrap"
    },
  
}));
