import React, { useReducer } from 'react'
import ConsultationReducer from './consultationReducer'
import ConsultationContext from './consultationContext'



const ConsultationState = (props) =>
{



    const initialState = { date: '.............', warn:true }

    const [state, dispatch] = useReducer(ConsultationReducer, initialState);

    const setDate = (date) =>
    {



        dispatch({
            type: 'SET_DATE',
            payload: date

        })


    }
    const onWarn = ()=>{
        dispatch({
            type: 'SET_WARN',
            payload: true
        })

    }
    
    const offWarn = ()=>{
        dispatch({
            type: 'SET_WARN',
            payload: false
        })

    }
    
    


    return (
        <ConsultationContext.Provider
            value={{
                ...state,
                setDate,
                offWarn,
                onWarn

            }}

        >

            {props.children}
        </ConsultationContext.Provider>
    )


}
export default ConsultationState