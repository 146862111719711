import { makeStyles } from '@material-ui/core/styles';
export const MedicineCss = makeStyles(theme => ({


    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
}))