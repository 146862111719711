export const LOGIN = 'LOGIN'
export const LOADING = 'LOADING'
export const ERROR = 'ERROR'
export const NEWPATIENT = 'NEWPATIENT'
export const MEDICINE =  'MEDICINE'
export const CONSULTATIONS = 'CONSULTATIONS'
export const XRAY = 'XRAY'
export const INVESTIGATION = 'INVESTIGATION'
export const ADD_NEW_PATIENT ='ADD_NEW_PATIENT'
export const PHYSIOTHERAPY ='PHYSIOTHERAPY'
export const FINANCEMAIN ='FINANCEMAIN'








