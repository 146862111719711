import { makeStyles } from '@material-ui/core/styles';


export const DashBoardStyle = makeStyles(theme => ({



  nav: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: '240',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '240',
  },
  content: {
    padding:theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
     
      marginLeft: "240px"
  }
  },
  toolbar:  theme.mixins.toolbar,
  centerElements: {
    display: "flex",
    justifyContent: "center"
  },
  txt: {
    marginLeft: '20px',
    
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  space: {
    display: 'flex',
    justifyContent: 'spaceBetween'
  },
  center: {
    margin: '0',
    position: 'absolute',
    top: '26%',
    left: '79%',
    // transform: 'translate(-50%, -50%)'
  }




}));